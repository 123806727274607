<script setup>
import { useNuxtApp } from "#app";
const backendStore = useBackendStore();
const { isEmbed } = backendStore;
const backend = useBackend();
const analytics = useAnalytics();
const { onWindowEvent } = useWindowEvents();
const { $triggerEvent } = useNuxtApp()

const props = defineProps({
  noMarginExpanding: Boolean,
  bannerName: {
    type: String,
    default: () => 'portal2-banner-1336x300'
  },
  includesBrand: String
})

const banners = ref([])
// const {data: banners} = await useAsyncData(async () => await backend.getBannersForSlot('portal2-banner-1336x300'));

onMounted(async () => {
  if(!props.includesBrand) {
    banners.value = await backend.getBannersForSlot(props.bannerName);
  } else {
    banners.value = await backend.getBannersForSlotByBrand(props.includesBrand ,props.bannerName);
  }


})

const slides = computed(() => {
  return banners.value?.data ? banners.value.data.sort(b => b.is_default ? -1 : 0) : []
});

const slideEnd = (indexData) => {
  const index = indexData.currentSlideIndex;
  analytics.analyticsLayer({
    event: 'view_promotion',
    creative_name: slides.value[index].photo_alt,
    url: slides.value[index].url.toLowerCase(),
  })
}

const slidesInit = () => {
  analytics.analyticsLayer({
    event: 'view_promotion',
    creative_name: slides?.value[0]?.photo_alt,
    url: slides?.value[0]?.url.toLowerCase(),
  })
}
const {breakpoint} = useBreakpoints();
const request = useRequestURL();
const domain = request.href;
const isMobileAspectRatio = computed(() => !breakpoint.value || breakpoint.value === 'sm');

const scale = ref(1);
const scaleTransform = computed(() => {
 const css = `scale(${scale.value})`;
 return css;
});

const heightScaled = computed(() => {
  const height = scale.value * 290;
  return `${height}px`;
});

const recalculateMobileSlide = () => {
  if(!isMobileAspectRatio) return;
  const currentWidth = window.innerWidth > 472 ? 472 : (window.innerWidth > 320 ? window.innerWidth : 320);
  const maxWidth = 472;
  scale.value = currentWidth / maxWidth;
}

const isSpecialBanner = (slide) => slide.photo_alt === 'Zawszetaniej';
const specialBannerClick = (slide) => {
  if(!isSpecialBanner(slide)) return;
  $triggerEvent('specialBanner:click');
}

onWindowEvent('resize',(e) => {
  recalculateMobileSlide();
});

onMounted(() => {
  recalculateMobileSlide();
})

</script>

<template>

  <div  v-if="!isEmbed" class="relative aspect-banner-mobile sm:aspect-banner-desktop bg-gray-100 z-10"
       :class="[{'-mx-4 sm:-mx-8': !noMarginExpanding}]">
    <BannerCarousel :items-to-show="1"
                    :wrap-around="true"
                    :autoplay="slides.length > 1 ? 5000 : 0"
                    :mouseDrag="slides.length > 1"
                    :touchDrag="slides.length > 1"
                    :i18n="{
                    ariaNextSlide: '',
                    ariaPreviousSlide: '',
                    ariaNavigateToSlide: '',
                    ariaGallery: '',
                    itemXofY: ' ',
                    iconArrowUp: ' ',
                    iconArrowDown: ' ',
                    iconArrowRight: ' ',
                    iconArrowLeft: ' '
                    }"
                    @slide-end="(index) => slideEnd(index)"
                    @init="slidesInit"
    >
      <template v-for="(slide,key) in slides" :key="key">
        <BannerSlide>
          <template v-if="slide.photo.endsWith('.html') ">
            <div class="block html-banner-holder"
              :style="{'max-height': isMobileAspectRatio ? heightScaled : 'auto'}"
            >
              <iframe v-if="isMobileAspectRatio" :src="slide.mobile_photo+'?click='+ (slide.url ? decodeURIComponent(slide.url.toLowerCase()) :'')"
                      class="html-mobile-banner"
                      :style="{'transform': scaleTransform}"></iframe>
              <iframe v-else :src="slide.photo+'?click='+ (slide.url ? decodeURIComponent(slide.url.toLowerCase()) :'')"
                      class="html-desktop-banner"></iframe>
            </div>
          </template>
          <template v-else>
            <NuxtLink :class="['relative w-full', {'cursor-default': !slide.url}]"
              :to="slide?.url || null"
                @click="analytics.analyticsLayer({
                event: 'select_promotion',
                creative_name: slide.photo_alt,
                type: slide.type ? slide.type : 'home_section_baner',
                slot: props.bannerName,
                url: slide?.url ? slide.url.toLowerCase() : null
                    })">
              <div class="hidden sm:block w-full aspect-banner-desktop bg-cover bg-center"
                   :class="{'cursor-pointer': isSpecialBanner(slide)}"
                   @click="() => specialBannerClick(slide)"
                   :style="{'backgroundImage':`url(${slide.photo})`}" />
              <div class="block sm:hidden w-full aspect-banner-mobile bg-cover bg-center"
                   :class="{'cursor-pointer': isSpecialBanner(slide)}"
                   @click="() => specialBannerClick(slide)"
                   :style="{'backgroundImage':`url(${slide.mobile_photo})`}" />
            </NuxtLink>
          </template>
        </BannerSlide>
      </template>
      <template v-if="slides.length > 1" #addons>
        <BannerNavigation />
      </template>
    </BannerCarousel>
  </div>
</template>
